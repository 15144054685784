import { ActionTree, ActionContext } from "vuex";
import { AuthenticationState, authState } from "./state";
import { AuthenticationMutation, AuthMutationTypes } from "./mutations";
import { RootState } from "@/store/interfaces";
import { State } from "./types";
import {
  ConfirmUserDetails,
  CreateUserInfo,
  LoginInfo,
  PasswordSetup,
  RefreshTokenInfo,
  Status,
  CordinatorRequest,
} from "@/types";
import axiosInstance from "@/services/axios";
import SessionStorageService from "@/services/sessionStorage";
import { generalPostRequest } from "@/utils/request";

export enum AuthActionTypes {
  LOGIN_USER = "LOGIN_USER",
  STORE_USER = "STORE_USER",
  RESEND_OTP = "RESEND_OTP",
  CREATE_CLIENT_ACCOUNT = "CREATE_CLIENT_ACCOUNT",
  CREATE_SALES_TEAM_ACCOUNT = "CREATE_SALES_TEAM_ACCOUNT",
  CREATE_SUPPORTUSERSELF_ACCOUNT = "CREATE_SUPPORTUSERSELF_ACCOUNT",
  CONFIRM_USER_ACCOUNT = "CONFIRM_USER_ACCOUNT",
  SET_PASSWORD = "SET_PASSWORD",
  SET_SALES_CUSTOMER_PASSWORD = "SET_SALES_CUSTOMER_PASSWORD",
  RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST",
  CONFIRM_RESET_PASSWORD = "CONFIRM_RESET_PASSWORD",
  RESET_PASSWORD = "RESET_PASSWORD",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  REFRESH_TOKEN = "REFRESH_TOKEN",
  UPDATE_CLIENT_USER = "UPDATE_CLIENT_USER",
  CREATE_SUPPORT_USER = "CREATE_SUPPORT_USER",
  GET_NOTIFICATIONS = "GET_NOTIFICATIONS",
  GET_NOTIFICATIONS_COUNT = "GET_NOTIFICATIONS_COUNT",
  COMPANY_VALIDATION = "COMPANY_VALIDATION",
  COMPANY_VALIDATION_CONFIRMATION = "COMPANY_VALIDATION_CONFIRMATION",
  CREATE_CORDINATOR_SUPPORT_USER = "CREATE_CORDINATOR_SUPPORT_USER",
  GET_CORDINATOR_SUPPORT_AGENTS = "GET_CORDINATOR_SUPPORT_AGENTS",
  SEND_SMS = "SEND_SMS",
  CONFIRM_CLIENT_SMS = "CONFIRM_CLIENT_SMS",
}

type AugmentedAuthActionContext = {
  commit<K extends keyof AuthenticationMutation>(
    key: K,
    payload: Parameters<AuthenticationMutation[K]>[1]
  ): ReturnType<AuthenticationMutation[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface AuthenticationAction {
  //LOGIN
  [AuthActionTypes.LOGIN_USER](
    { commit }: AugmentedAuthActionContext,
    loginCredentials: LoginInfo
  ): Promise<any>;

  // CREATE USER ACCOUNT
  [AuthActionTypes.CREATE_CLIENT_ACCOUNT](
    { commit }: AugmentedAuthActionContext,
    createUserInfo: CreateUserInfo
  ): Promise<any>;

  // CREATE USER ACCOUNT
  [AuthActionTypes.CREATE_SUPPORTUSERSELF_ACCOUNT](
    { commit }: AugmentedAuthActionContext,
    createUserInfo: CreateUserInfo
  ): Promise<any>;

  // CREATE SALES USER ACCOUNT
  [AuthActionTypes.CREATE_SALES_TEAM_ACCOUNT](
    { commit }: AugmentedAuthActionContext,
    createUserInfo: CreateUserInfo
  ): Promise<any>;

  // STORE USER ACCOUNT
  [AuthActionTypes.STORE_USER](
    { commit }: AugmentedAuthActionContext,
    userCredentials: CreateUserInfo
  ): any;

  // VERIFY USER ACCOUNT
  [AuthActionTypes.CONFIRM_USER_ACCOUNT](
    { commit }: AugmentedAuthActionContext,
    userDetail: ConfirmUserDetails
  ): Promise<any>;

  // SETUP PASSWORD
  [AuthActionTypes.SET_PASSWORD](
    { commit }: AugmentedAuthActionContext,
    password: PasswordSetup
  ): Promise<any>;

  // SETUP SALES CUSTOMER PASSWORD
  [AuthActionTypes.SET_SALES_CUSTOMER_PASSWORD](
    { commit }: AugmentedAuthActionContext,
    password: PasswordSetup
  ): Promise<any>;

  // REQUEST PASSWORD REQUEST
  [AuthActionTypes.RESET_PASSWORD_REQUEST](
    { commit }: AugmentedAuthActionContext,
    payload: any
  ): Promise<any>;

  // CONFIRM_RESET_PASSWORD
  [AuthActionTypes.CONFIRM_RESET_PASSWORD](
    { commit }: AugmentedAuthActionContext,
    payload: any
  ): Promise<any>;

  // REQUEST PASSWORD
  [AuthActionTypes.RESET_PASSWORD](
    { commit }: AugmentedAuthActionContext,
    payload: any
  ): Promise<any>;

  // RESEND OTP
  [AuthActionTypes.RESEND_OTP](
    { commit }: AugmentedAuthActionContext,
    payload: any
  ): Promise<any>;

  // SEND SMS
  [AuthActionTypes.SEND_SMS](
    { commit }: AugmentedAuthActionContext,
    payload: any
  ): Promise<any>;

  // RESEND SMS TO USER
  [AuthActionTypes.CONFIRM_CLIENT_SMS](
    { commit }: AugmentedAuthActionContext,
    payload: any
  ): Promise<any>;

  // REFRESH TOKEN
  [AuthActionTypes.REFRESH_TOKEN](
    { commit }: AugmentedAuthActionContext,
    payload: any
  ): Promise<any>;

  // UPDATE PROFILE
  [AuthActionTypes.UPDATE_CLIENT_USER](
    { commit }: AugmentedAuthActionContext,
    payload: any
  ): Promise<any>;

  // CREATE SUPPORT USER ACCOUNT
  [AuthActionTypes.CREATE_SUPPORT_USER](
    { commit }: AugmentedAuthActionContext,
    createUserInfo: CreateUserInfo
  ): Promise<any>;

  // GET NOTIFICATION
  [AuthActionTypes.GET_NOTIFICATIONS](
    { commit }: AugmentedAuthActionContext,
    request?: string
  ): Promise<any>;

  // GET NOTIFICATION COUNT
  [AuthActionTypes.GET_NOTIFICATIONS_COUNT](
    { commit }: AugmentedAuthActionContext,
    request?: string
  ): Promise<any>;

  // VERIFY USER WORK EMAIL
  [AuthActionTypes.COMPANY_VALIDATION](
    { commit }: AugmentedAuthActionContext,
    userDetail: any
  ): Promise<any>;

  // VALIDATE USER WORK EMAIL
  [AuthActionTypes.COMPANY_VALIDATION_CONFIRMATION](
    { commit }: AugmentedAuthActionContext,
    userDetail: any
  ): Promise<any>;

  // CREATE STATE CORDINATOR AGENT ACCOUNT
  [AuthActionTypes.CREATE_CORDINATOR_SUPPORT_USER](
    { commit }: AugmentedAuthActionContext,
    createUserInfo: CreateUserInfo
  ): Promise<any>;

  // CREATE STATE CORDINATOR AGENT ACCOUNT
  [AuthActionTypes.GET_CORDINATOR_SUPPORT_AGENTS](
    { commit }: AugmentedAuthActionContext,
    request: CordinatorRequest
  ): Promise<any>;
}

export const authActions: ActionTree<AuthenticationState, RootState> &
  AuthenticationAction = {
  [AuthActionTypes.LOGIN_USER](
    { commit },
    loginCredentials: LoginInfo
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(AuthMutationTypes.LOGIN_USER_LOADING, Status.LOADING);
      axiosInstance.post("ClientUser/Login", loginCredentials).then(
        (response: any) => {
          localStorage.clear();
          commit(AuthMutationTypes.LOGIN_USER_SUCCESS, response.data);
          authState.authenticatedUser = response.data;
          SessionStorageService.setItem(
            "token",
            authState.authenticatedUser.token
          );
          resolve(response);
        },
        (error: any) => {
          commit(AuthMutationTypes.LOGIN_USER_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
    // return generalPostRequest(
    //   commit,
    //   "ClientUser/Login",
    //   [
    //     AuthMutationTypes.LOGIN_USER_LOADING,
    //     AuthMutationTypes.LOGIN_USER_SUCCESS,
    //     AuthMutationTypes.LOGIN_USER_ERROR,
    //   ],
    //   loginCredentials
    // );
  },

  //store User
  [AuthActionTypes.STORE_USER](
    { commit }: AugmentedAuthActionContext,
    userCredentials: any
  ) {
    commit(AuthMutationTypes.STORE_USER, userCredentials);
  },

  //Create User Account
  [AuthActionTypes.CREATE_CLIENT_ACCOUNT](
    { commit },
    userCredentials: CreateUserInfo
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(AuthMutationTypes.CREATE_CLIENT_ACCOUNT_LOADING, Status.LOADING);
      axiosInstance.post("ClientUser/CreateClientUser", userCredentials).then(
        (response) => {
          commit(AuthMutationTypes.CREATE_CLIENT_ACCOUNT_SUCCESS, response);
          resolve(response);
        },
        (error) => {
          commit(AuthMutationTypes.CREATE_CLIENT_ACCOUNT_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  //Create Support User self creation
  [AuthActionTypes.CREATE_SUPPORTUSERSELF_ACCOUNT](
    { commit },
    userCredentials: CreateUserInfo
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(AuthMutationTypes.CREATE_CLIENT_ACCOUNT_LOADING, Status.LOADING);
      axiosInstance
        .post("ClientUser/SupportUserSelfCreation", userCredentials)
        .then(
          (response) => {
            commit(AuthMutationTypes.CREATE_CLIENT_ACCOUNT_SUCCESS, response);
            resolve(response);
          },
          (error) => {
            commit(AuthMutationTypes.CREATE_CLIENT_ACCOUNT_ERROR, Status.ERROR);
            reject(error);
          }
        );
    });
  },

  //Create User Account
  [AuthActionTypes.CREATE_SALES_TEAM_ACCOUNT](
    { commit },
    userCredentials: CreateUserInfo
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(AuthMutationTypes.CREATE_CLIENT_ACCOUNT_LOADING, Status.LOADING);
      axiosInstance
        .post("ClientUser/SalesTeamUserSelfCreation", userCredentials)
        .then(
          (response) => {
            commit(AuthMutationTypes.CREATE_CLIENT_ACCOUNT_SUCCESS, response);
            resolve(response);
          },
          (error) => {
            commit(AuthMutationTypes.CREATE_CLIENT_ACCOUNT_ERROR, Status.ERROR);
            reject(error);
          }
        );
    });
  },

  // Verify user account
  async [AuthActionTypes.CONFIRM_USER_ACCOUNT](
    { commit },
    userDetail: ConfirmUserDetails
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      [AuthMutationTypes.CONFIRM_USER_ACCOUNT_LOADING];
      axiosInstance.post("ClientUser/ConfirmUserAccount", userDetail).then(
        (response) => {
          [AuthMutationTypes.CONFIRM_USER_ACCOUNT_SUCCESS, response];
          resolve(true);
        },
        (error) => {
          [AuthMutationTypes.CONFIRM_USER_ACCOUNT_ERROR];
          reject(error);
        }
      );
    });
  },

  // Setup Password
  async [AuthActionTypes.SET_PASSWORD](
    { commit },
    password: PasswordSetup
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(AuthMutationTypes.SET_PASSWORD_LOADING, Status.LOADING);
      axiosInstance.post("ClientUser/SetPassword", password).then(
        (response: any) => {
          commit(AuthMutationTypes.SET_PASSWORD_SUCCESS, response.data);
          authState.authenticatedUser = response.data;
          SessionStorageService.setItem(
            "token",
            authState.authenticatedUser.token
          );
          resolve(response);
        },
        (error) => {
          commit(AuthMutationTypes.SET_PASSWORD_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  // Setup Password
  async [AuthActionTypes.SET_SALES_CUSTOMER_PASSWORD](
    { commit },
    password: PasswordSetup
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(AuthMutationTypes.SET_PASSWORD_LOADING, Status.LOADING);
      axiosInstance.post("ClientUser/SetPassword", password).then(
        (response: any) => {
          commit(
            AuthMutationTypes.SET_SALES_CUSTOMER_PASSWORD_SUCCESS,
            response.data
          );
          resolve(response);
        },
        (error) => {
          commit(AuthMutationTypes.SET_PASSWORD_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  // Reset Password Request
  async [AuthActionTypes.RESET_PASSWORD_REQUEST](
    { commit },
    payload: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      [AuthMutationTypes.RESET_PASSWORD_REQUEST_LOADING];
      axiosInstance.post("ClientUser/ResetPasswordRequest", payload).then(
        (response) => {
          [AuthMutationTypes.RESET_PASSWORD_REQUEST_SUCCESS, response];
          resolve(response);
        },
        (error) => {
          [AuthMutationTypes.RESET_PASSWORD_REQUEST_ERROR];
          reject(error);
        }
      );
    });
  },

  // Reset Password Token Request
  async [AuthActionTypes.CONFIRM_RESET_PASSWORD](
    { commit },
    payload: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      [AuthMutationTypes.CONFIRM_RESET_PASSWORD_LOADING];
      axiosInstance.post("ClientUser/ConfirmResetPasswordToken", payload).then(
        (response) => {
          [AuthMutationTypes.CONFIRM_RESET_PASSWORD_SUCCESS, response];
          resolve(response);
        },
        (error) => {
          [AuthMutationTypes.CONFIRM_RESET_PASSWORD_ERROR];
          reject(error);
        }
      );
    });
  },

  // Reset Password proper Request
  async [AuthActionTypes.RESET_PASSWORD](
    { commit },
    payload: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      [AuthMutationTypes.RESET_PASSWORD_LOADING];
      axiosInstance.post("ClientUser/ResetPassword", payload).then(
        (response) => {
          [AuthMutationTypes.RESET_PASSWORD_SUCCESS, response];
          resolve(response);
        },
        (error) => {
          [AuthMutationTypes.RESET_PASSWORD_ERROR];
          reject(error);
        }
      );
    });
  },

  // RESEND OTP
  async [AuthActionTypes.RESEND_OTP]({ commit }, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      [AuthMutationTypes.RESEND_OTP_LOADING];
      axiosInstance.post("ClientUser/ResendOtp", payload).then(
        (response) => {
          [AuthMutationTypes.RESEND_OTP_SUCCESS, response];
          resolve(response);
        },
        (error) => {
          [AuthMutationTypes.RESEND_OTP_ERROR];
          reject(error);
        }
      );
    });
  },

  // SEND SMS OTP
  async [AuthActionTypes.SEND_SMS]({ commit }, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      [AuthMutationTypes.RESEND_OTP_LOADING];
      axiosInstance.post("Utility/SendClientUserSmsOtp", payload).then(
        (response) => {
          commit(AuthMutationTypes.SEND_SMS_OTP_LOADING, Status.LOADING);
          resolve(response);
        },
        (error) => {
          commit(AuthMutationTypes.SEND_SMS_OTP_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  // CONFIRM SMS OTP
  async [AuthActionTypes.CONFIRM_CLIENT_SMS]({ commit }, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      [AuthMutationTypes.RESEND_OTP_LOADING];
      axiosInstance.post("Utility/ConfirmPhoneOtp", payload).then(
        (response) => {
          commit(AuthMutationTypes.CONFIRM_SMS_OTP_LOADING, Status.LOADING);
          resolve(response);
        },
        (error) => {
          commit(AuthMutationTypes.CONFIRM_SMS_OTP_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  //Change password
  [AuthActionTypes.CHANGE_PASSWORD](
    { commit },
    userCredentials: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(AuthMutationTypes.CHANGE_PASSWORD_LOADING, Status.LOADING);
      axiosInstance.post("ClientUser/ChangePassword", userCredentials).then(
        (response) => {
          commit(AuthMutationTypes.CHANGE_PASSWORD_SUCCESS, response);
          resolve(response);
        },
        (error) => {
          commit(AuthMutationTypes.CHANGE_PASSWORD_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  //REFRESH TOKEN
  [AuthActionTypes.REFRESH_TOKEN](
    { commit },
    payload: RefreshTokenInfo
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(AuthMutationTypes.REFRESH_TOKEN_LOADING, Status.LOADING);
      axiosInstance.post("ClientUser/RefreshUserToken", payload).then(
        (response: any) => {
          commit(AuthMutationTypes.REFRESH_TOKEN_SUCCESS, response.data);
          // authState.authenticatedUser = response.data;
          // SessionStorageService.setItem(
          //   "token",
          //   authState.authenticatedUser.token
          // );
          resolve(response);
        },
        (error: any) => {
          commit(AuthMutationTypes.REFRESH_TOKEN_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  // Update client profile
  [AuthActionTypes.UPDATE_CLIENT_USER]({ commit }, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(AuthMutationTypes.UPDATE_CLIENT_USER_LOADING, Status.LOADING);
      axiosInstance.post("ClientUser/UpdateClientUser", payload).then(
        (response: any) => {
          commit(AuthMutationTypes.UPDATE_CLIENT_USER_SUCCESS, response.data);
          // authState.authenticatedUser.user = response.data.clientUser;
          resolve(response);
        },
        (error: any) => {
          commit(AuthMutationTypes.UPDATE_CLIENT_USER_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  //Create User Account
  [AuthActionTypes.CREATE_SUPPORT_USER](
    { commit },
    userCredentials: CreateUserInfo
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(AuthMutationTypes.CREATE_SUPPORT_USER_LOADING, Status.LOADING);
      axiosInstance.post("ClientUser/CreateSupportUser", userCredentials).then(
        (response) => {
          commit(AuthMutationTypes.CREATE_SUPPORT_USER_SUCCESS, response);
          resolve(response);
        },
        (error) => {
          commit(AuthMutationTypes.CREATE_SUPPORT_USER_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  //Create User Account
  [AuthActionTypes.GET_NOTIFICATIONS]({ commit }): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(AuthMutationTypes.GET_NOTIFICATIONS_LOADING, Status.LOADING);
      axiosInstance.get("ClientUser/GetNotifications").then(
        (response) => {
          commit(
            AuthMutationTypes.GET_NOTIFICATIONS_SUCCESS,
            response.data.data
          );
          resolve(response);
        },
        (error) => {
          commit(AuthMutationTypes.GET_NOTIFICATIONS_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  //Create User Account
  [AuthActionTypes.GET_NOTIFICATIONS_COUNT]({ commit }): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(AuthMutationTypes.GET_NOTIFICATIONS_COUNT_LOADING, Status.LOADING);
      axiosInstance.get("ClientUser/GetNotificationCount").then(
        (response) => {
          commit(
            AuthMutationTypes.GET_NOTIFICATIONS_COUNT_SUCCESS,
            response.data
          );
          resolve(response);
        },
        (error) => {
          commit(AuthMutationTypes.GET_NOTIFICATIONS_COUNT_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  // Verify user WORK EMAIL
  async [AuthActionTypes.COMPANY_VALIDATION](
    { commit },
    userDetail: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(AuthMutationTypes.COMPANY_VALIDATION_LOADING, Status.LOADING);
      axiosInstance.post("ClientUser/CompanyValidationOTP", userDetail).then(
        (response) => {
          [AuthMutationTypes.COMPANY_VALIDATION_SUCCESS, response];
          resolve(true);
        },
        (error) => {
          commit(AuthMutationTypes.COMPANY_VALIDATION_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  // VALIDATE user WORK EMAIL
  async [AuthActionTypes.COMPANY_VALIDATION_CONFIRMATION](
    { commit },
    userDetail: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        AuthMutationTypes.COMPANY_VALIDATION_CONFIRMATION_LOADING,
        Status.LOADING
      );
      axiosInstance
        .post("ClientUser/CompanyValidationOTPConfirmation", userDetail)
        .then(
          (response) => {
            [
              AuthMutationTypes.COMPANY_VALIDATION_CONFIRMATION_SUCCESS,
              response,
            ];
            resolve(true);
          },
          (error) => {
            commit(
              AuthMutationTypes.COMPANY_VALIDATION_CONFIRMATION_ERROR,
              Status.ERROR
            );
            reject(error);
          }
        );
    });
  },

  //Create CORDINATOR AGENT creation
  [AuthActionTypes.CREATE_CORDINATOR_SUPPORT_USER](
    { commit },
    userCredentials: CreateUserInfo
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(AuthMutationTypes.CREATE_CLIENT_ACCOUNT_LOADING, Status.LOADING);
      axiosInstance
        .post("ClientUser/SalesTeamUserCreationByCordinator", userCredentials)
        .then(
          (response) => {
            commit(AuthMutationTypes.CREATE_CLIENT_ACCOUNT_SUCCESS, response);
            resolve(response);
          },
          (error) => {
            commit(AuthMutationTypes.CREATE_CLIENT_ACCOUNT_ERROR, Status.ERROR);
            reject(error);
          }
        );
    });
  },

  [AuthActionTypes.GET_CORDINATOR_SUPPORT_AGENTS](
    { commit },
    payload: CordinatorRequest
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        AuthMutationTypes.GET_STATE_CORDINATOR_AGENT_LOADING,
        Status.LOADING
      );
      axiosInstance
        .get(
          `ClientUser/GetSalesTeamMembersUnderCordinator?PageIndex=${payload.PageIndex}&PageSize=${payload.PageSize}`
        )
        .then(
          (response) => {
            commit(
              AuthMutationTypes.GET_STATE_CORDINATOR_AGENT_SUCCESS,
              response.data
            );
            resolve(response);
          },
          (error) => {
            commit(
              AuthMutationTypes.GET_STATE_CORDINATOR_AGENT_ERROR,
              Status.ERROR
            );
            reject(error);
          }
        );
    });
  },
};
